import React, { useState, useEffect } from 'react';
import { Grid, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { useZoom } from '../hooks/useZoom';
import Modal from 'components/UI/Modal';
import { useDispatch, useSelector } from 'react-redux';
import useAccount from 'hooks/useAccount';
import axiosInstance from 'utils/axiosInstance';
import { showPopupToUpdateServiceProviderToZoom } from 'services/user.service';
import { updateUser } from 'actions/update-user';
const moment = require('moment');

const ZoomHeader = styled(Grid)`
  // border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 16px 0px;
`;
const ZoomBodyHeader = styled(Grid)`
  padding: 16px;
`;
const CardMainContainer = styled.div`
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 4px;
  padding: 0px 0px 16px 0px;
`;
const WarningWrapper = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  color: #000000de;
`;
const ZoomFooter = styled(Grid)`
  padding: 16px 0px;
`;
export const ZoomIntegration = ({ joyRideActive, setGetStarted = () => {} }) => {
  const theme = useTheme();
  const { user } = useAccount();
  const dispatch = useDispatch();
  const [showZoomPopUp, setShowZoomPopUp] = useState(true);
  const isAnyContributionJoinedOrCreated = useSelector(state => state.contributions.isAnyContributionJoinedOrCreated);
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { authorizeAccountHref, isAccountConnected, isLoading, onDisconnect } = useZoom();
  const buttonText = isLoading ? 'Processing...' : '';
  const [zoomPopUpOpen, setZoomPopUpOpen] = useState(false);
  const userCreatedTime = moment(user.createTime).startOf('day'); // Ignore time
  const thresholdDate = moment('2025-03-18').startOf('day'); // Ignore time

  const resultant = userCreatedTime.isSameOrAfter(thresholdDate);
  const handlehowToVideos = ({}) => {
    window.open('https://cohere2298.zendesk.com/hc/en-us/articles/22900870664980', '_blank');
  };

  const handleConnectClick = () => {
    setGetStarted(false);
    setZoomPopUpOpen(true);
  };

  const submitHandler = async () => {
    await axiosInstance.post(`Contribution/UpdateServiceProvider`).then(() => {
      hidePopUpForZoom();
    });
  };

  const hidePopUpForZoom = () => {
    showPopupToUpdateServiceProviderToZoom(true).then(() => {
      dispatch(
        updateUser({
          ...user,
          showZoomPopup: true,
        }),
      );
      setShowZoomPopUp(true);
    });
  };
  useEffect(() => {
    if (
      isAccountConnected &&
      isAnyContributionJoinedOrCreated &&
      resultant &&
      user.showZoomPopup === false &&
      joyRideActive === false
    ) {
      setShowZoomPopUp(false);
    }
  }, [isAccountConnected, isAnyContributionJoinedOrCreated, joyRideActive]);
  console.log('showZoomPopUp', user.showZoomPopup);
  return (
    <CardMainContainer id="integration3">
      <Grid>
        <ZoomHeader>
          <Grid>
            <TableHead>
              <TableRow>
                <Typography variant="h6" style={{ display: 'inline-block' }}>
                  Zoom Integration
                </Typography>
                {/* {isAccountConnected && (
                  <div class="inline-block" style={{ marginLeft: 20 }}>
                    Your Zoom account is currently connected. Click disconnect to uninstall.
                  </div>
                )} */}
              </TableRow>
            </TableHead>
          </Grid>
          {/* <Grid>
            <Button autoWidth mobileView={mobileView} onClick={handlehowToVideos} style={{ marginRight: 10 }}>
              Help Articles
            </Button>
            {!isAccountConnected && (
              // <Link href={authorizeAccountHref}>
              <Button autoWidth disabled={isLoading} mobileView={mobileView} onClick={handleConnectClick}>
                {isLoading ? buttonText : 'Connect'}
              </Button>
              // </Link>
            )}
            {isAccountConnected && (
              <Button autoWidth disabled={isLoading} mobileView={mobileView} onClick={onDisconnect}>
                {isLoading ? buttonText : 'Disconnect'}
              </Button>
            )}
          </Grid> */}
        </ZoomHeader>
        {!isAccountConnected ? (
          <>
            {/* <ZoomBodyHeader>
              <TableHead>
                <TableRow>
                  <Typography variant="h6" style={{ display: 'inline-block' }}>
                    Zoom
                  </Typography>
                  {isAccountConnected && (
                    <div class="inline-block" style={{ marginLeft: 20 }}>
                      Your Zoom account is currently connected. Click disconnect to uninstall.
                    </div>
                  )}
                </TableRow>
              </TableHead>
            </ZoomBodyHeader> */}
            <Grid>
              <WarningWrapper>
                You will need a paid version of Zoom to enjoy the full benefits of connecting your account. Full
                benefits include automatic Cohere synchronization of session recordings and chat logs and automatic
                client notifications when recordings are available. Free Zoom accounts will still set up sessions, but
                other features may not work as expected.
              </WarningWrapper>
            </Grid>
          </>
        ) : (
          <Grid>
            <WarningWrapper>Your Zoom account is currently connected. Click disconnect to uninstall.</WarningWrapper>
          </Grid>
        )}
        <ZoomFooter>
          <Button autoWidth mobileView={mobileView} onClick={handlehowToVideos} style={{ marginRight: 10 }}>
            How-To Video
          </Button>
          {!isAccountConnected ? (
            // <Link href={authorizeAccountHref}>
            <Button autoWidth disabled={isLoading} mobileView={mobileView} onClick={handleConnectClick}>
              {isLoading ? buttonText : 'Connect'}
            </Button>
          ) : (
            // </Link>
            <Button autoWidth disabled={isLoading} mobileView={mobileView} onClick={onDisconnect}>
              {isLoading ? buttonText : 'Disconnect'}
            </Button>
          )}
          {/* {isAccountConnected && (
            <Button autoWidth disabled={isLoading} mobileView={mobileView} onClick={onDisconnect}>
              {isLoading ? buttonText : 'Disconnect'}
            </Button>
          )} */}
        </ZoomFooter>

        <Modal
          isOpen={zoomPopUpOpen}
          submitTitle="Never mind"
          onSubmit={() => {
            setZoomPopUpOpen(false);
          }}
          title="Zoom Integration"
          onCancel={() => {
            window.open(authorizeAccountHref);
          }}
          onCloseClick={() => {
            setZoomPopUpOpen(false);
          }}
          closeClick={true}
          cancelTitle="Understood, Connect Zoom"
          shrinkCancelButton={true}
        >
          <p>
            Zoom integration is only compatible with paid zoom accounts, if you have a Free Zoom account please upgrade
            or use Cohere Video Service.
          </p>
        </Modal>

        <Modal
          isOpen={showZoomPopUp === false}
          submitTitle="Yes"
          cancelTitle="No"
          onSubmit={() => {
            submitHandler();
          }}
          title="Set Zoom as Your Default Provider?"
          onCancel={() => {
            hidePopUpForZoom();
          }}
          onCloseClick={() => {
            hidePopUpForZoom();
          }}
          closeClick={true}
          shrinkCancelButton={true}
        >
          <p>
            You’re about to make Zoom your default video provider. Your clients will receive an email with the updated
            Zoom link for any services they’re enrolled in. Additionally, all existing services will be updated with the
            new Zoom link.
          </p>
        </Modal>
      </Grid>
    </CardMainContainer>
  );
};
