import React, { useEffect, useState } from 'react';
import { useAccount, useHeader, useHttp, useRouter, useVideoChatActions } from 'hooks';
import { useParams, Link, Route } from 'react-router-dom';
import Loader from 'components/UI/Loader';
import PropTypes from 'prop-types';
import './ClientPage.scss';
import { useMediaQuery } from '@mui/material';
import withStyles from '@material-ui/core/styles/withStyles';
import { TableRow, TableCell, useTheme } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import {
  getAllUpCommingSessionsOfClient,
  getClientBasicInfo,
  getContractAndESignatures,
  getEnrolledContributionsOfClient,
  getRecentPaymentsOfClient,
} from 'services/clients.service';
import VideocamIcon from '@mui/icons-material/Videocam';
import DownloadIcon from '@mui/icons-material/VerticalAlignBottom';
import moment from 'moment';
import { addhttp } from 'utils/utils';
import styled from 'styled-components';
import ClientHeader from './components/ClientHeader';
import ClientDetail from './components/ClientDetail';
import CellToolTip from './components/CellToolTip';
import jsPDF from 'jspdf';
import { redirectTo } from 'services/links';
import { useRecordModal } from 'hooks/useSessionRecordModal';

const StyledSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

const LaunchSessionDiv = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-decoration: underline;
  cursor: pointer;

  &:hover span {
    font-weight: bold;
  }
`;

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);

const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})(TableCell);

const ContributionTableHeaders = [
  { title: 'Service Name', width: '37%', isContributionTitle: true },
  { title: 'Join Date', width: '37%' },
  { title: 'Payment', width: '26%' },
];
const sessionTableHeaders = [
  { title: 'Service Name', width: '37%' },
  { title: 'Session Date', width: '37%' },
  { title: 'Sessions', width: '26%' },
];
const paymentTableHeaders = [
  { title: 'Date', width: '37%' },
  { title: 'Service Name', width: '37%' },
  { title: 'Amount', width: '26%' },
];
const contractTableHeaders = [
  { title: 'Document', width: '36%' },
  { title: 'Signature', width: '27%' },
  { title: 'Date; Time', width: '37%' },
];

const ClientPage = ({ history, isLeadPage }) => {
  useHeader('Client');

  const params = useParams();
  const theme = useTheme();
  const { domain } = useRouter();
  const { request } = useHttp();
  const { user } = useAccount();
  const { startVideoChat } = useVideoChatActions();
  const [row, setRow] = useState(null);
  const mobileView = useMediaQuery(theme.breakpoints.down('900'));
  const pageSize = 3;
  const page = 1;

  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [contributions, setContributions] = useState([]);
  const [payments, setPayments] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [contributionTablePage, setContributionTablePage] = useState(page);
  const [sessionTablePage, setSessionTablePage] = useState(page);
  const [paymentTablePage, setPaymentTablePage] = useState(page);
  const [contractsTablePage, setContractsTablePage] = useState(page);
  const [response, setResponse] = useState(false);
  const options = {
    orientation: 'portrait',
    format: [297, 210],
    unit: 'px',
  };
  const getContributionLink = (id, isLeadMagnet, isSession = false) => {
    if (isLeadPage) {
      return isLeadMagnet
        ? `/lead-magnet-view/${id}/landingpage`
        : isSession
        ? `/contribution-view/${id}/sessions`
        : `/contribution-view/${id}/about`;
    } else {
      return isSession ? `/contribution-view/${id}/sessions` : `/contribution-view/${id}/about`;
    }
  };

  const getClientInfo = async clientId => {
    if (!clientId) {
      if (isLeadPage) {
        history.push('/leads');
      } else {
        history.push('/clients');
      }
    }

    setLoadingPage(true);

    // const [clientData, sessionData, contributionsData, paymentsData, contractsData] = await Axios.all([
    //   getClientBasicInfo(clientId),
    //   getAllUpCommingSessionsOfClient(clientId, page, pageSize),
    //   getEnrolledContributionsOfClient(clientId, contributionTablePage, pageSize),
    //   getRecentPaymentsOfClient(clientId, paymentTablePage, pageSize),
    //   getContractAndESignatures(clientId, contractsTablePage, pageSize),
    // ]);
    const results = await Promise.allSettled([
      getClientBasicInfo(clientId),
      getAllUpCommingSessionsOfClient(clientId, page, pageSize),
      getEnrolledContributionsOfClient(clientId, contributionTablePage, pageSize),
      getRecentPaymentsOfClient(clientId, paymentTablePage, pageSize),
      getContractAndESignatures(clientId, contractsTablePage, pageSize),
    ]);

    const [clientData, sessionData, contributionsData, paymentsData, contractsData] = results.map(result => {
      if (result.status === 'fulfilled') {
        setResponse(true);
        return result.value;
      } else {
        setResponse(null);
        return null;
      }
    });

    if (clientData === null) {
      setClient({
        name: params?.name,
        email: params?.email,
        id: params?.id,
      });
    } else {
      setClient(clientData);
    }
    if (sessionData === null) {
      setSessions({ UpcomingSessionList: [], TotalCount: 0 });
    } else {
      setSessions(sessionData);
    }
    if (contributionsData === null) {
      setContributions({ EnrolledContributions: [], TotalCount: 0 });
    } else {
      setContributions(contributionsData);
    }
    if (paymentsData === null) {
      setPayments({ RecentPayments: [], TotalCount: 0 });
    } else {
      setPayments(paymentsData);
    }
    if (contractsData === null) {
      setContracts({ Contracts: [], TotalCount: 0 });
    } else {
      setContracts(contractsData);
    }
    setLoadingPage(false);
  };

  const openChat = clientId => {
    window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${clientId}`));
  };

  useEffect(() => {
    getClientInfo(params?.id);
  }, [params?.id]);

  const getSessionTIme = (dateTime, timezone) => {
    return moment(dateTime).format('MMMM Do h:mma ') + timezone;
  };

  const getJoinDate = date => {
    return moment(date).format('MMMM D, Y');
  };

  const renderHeaders = headers => {
    return headers.map(header => (
      <BoldTableCell width={header.width}>
        {header.isContributionTitle && isLeadPage ? 'Services/Lead Magnet' : header.title}
      </BoldTableCell>
    ));
  };
  const handleLaunchSession = ({
    contributionId,
    classId,
    classGroupId,
    title,
    chatSid,
    type,
    RecordParticipantsOnConnect,
  }) => {
    request('/Video/CreateRoomAndGetToken', 'POST', {
      contributionId,
      classId: classId,
      RecordParticipantsOnConnect,
      identityName: `${user.firstName} ${user.lastName}`,
    }).then(({ room: { sid: roomId }, token }) =>
      startVideoChat({
        contributionId,
        type,
        sessionId: classId,
        classId: classGroupId,
        title: title,
        roomId,
        chatId: chatSid,
        token,
        deleteRoomOnVideoEnd: true,
      }),
    );
  };

  const onSubmit = () => {
    handleLaunchSession({ ...row, RecordParticipantsOnConnect: true });
  };
  const onCancel = () => {
    handleLaunchSession({ ...row, RecordParticipantsOnConnect: false });
  };

  const modalOptions = {
    onSubmit,
    onCancel,
    classId: row?.classId,
    contributionType: row?.type,
    contributionId: row?.contributionId,
    zoomStartUrl: row?.zoomStartUrl,
    liveVideoServiceProvider: row?.liveVideoServiceProvider,
  };
  const { RecordModal, launchRecodModal } = useRecordModal(modalOptions);
  const getPageCount = total => {
    return Math.ceil(total / pageSize);
  };

  const getPaymentText = (amount, symbol, currency) => {
    return amount === 0 ? 'Free' : `${currency} ${symbol}${parseFloat(amount).toFixed(2)}`;
  };

  const getPaymentClientName = (name, joinDate) => {
    // return `${name}; ${moment(joinDate).format('MMMM D, Y')}`;
    return `${moment(joinDate).format('MMMM D, Y')}`;
  };

  const getContractName = (name, signDate) => {
    return `${name}-${moment(signDate).format('MMMMY')}`;
  };

  const getContractDateTime = signDate => {
    return moment(signDate).format('MMMM D, Y; hh:mm a');
  };

  const getPaginationClass = (size, adjacentSectionSize) => {
    if (size <= 1) {
      return mobileView || adjacentSectionSize <= 1 ? 'd-none' : 'invisible';
    }
    return '';
  };

  const getNoDataRecord = () => {
    return mobileView ? (
      <div className="client-info-detail">
        <p className="text-center">No Records found</p>
      </div>
    ) : (
      <TableRow>
        <NarrowTableCell colSpan={3} align="center">
          No Record found
        </NarrowTableCell>
      </TableRow>
    );
  };

  const renderContributionElements = data => {
    if (!data || data.length === 0) {
      return getNoDataRecord();
    }
    return data.map(row => {
      return mobileView ? (
        <div className="client-info-detail">
          <p>
            <Link to={getContributionLink(row.contributionId, row.isLeadMagnet)} className="contributions-link">
              {row.contributionTitle}
            </Link>
          </p>
          <p>Join Date: {getJoinDate(row.joinDate)}</p>
          <p>Payment: {row.paymentStatus}</p>
        </div>
      ) : (
        <TableRow>
          <NarrowTableCell>
            <CellToolTip title={row.contributionTitle}>
              <Link to={getContributionLink(row.contributionId, row.isLeadMagnet)} className="contributions-link">
                {row.contributionTitle}
              </Link>
            </CellToolTip>
          </NarrowTableCell>
          <NarrowTableCell>
            <CellToolTip title={getJoinDate(row.joinDate)}>
              <span>{getJoinDate(row.joinDate)}</span>
            </CellToolTip>
          </NarrowTableCell>
          <NarrowTableCell>{row.paymentStatus}</NarrowTableCell>
        </TableRow>
      );
    });
  };

  const renderUpcomingSessionsElements = data => {
    if (!data || data.length === 0) {
      return getNoDataRecord();
    }
    return data.map(row => {
      return mobileView ? (
        <div className="client-info-detail">
          <p>{row.contributionName}</p>
          <p>Session Date: {getSessionTIme(row.startTime, row.timeZoneShortName)}</p>
          <p>
            Session: <VideocamIcon className="mx-2" /> {row.title}
          </p>
        </div>
      ) : (
        <TableRow>
          <NarrowTableCell>
            <CellToolTip title={row.contributionName}>
              <StyledSpan
                onClick={() => {
                  redirectTo(getContributionLink(row.contributionId, row.isLeadMagnet));
                }}
              >
                {row.contributionName}
              </StyledSpan>
            </CellToolTip>
          </NarrowTableCell>
          <NarrowTableCell>
            <CellToolTip title={getSessionTIme(row.startTime, row.timeZoneShortName)}>
              <span>{getSessionTIme(row.startTime, row.timeZoneShortName)}</span>
            </CellToolTip>
          </NarrowTableCell>
          <NarrowTableCell>
            <CellToolTip title={row.title}>
              <LaunchSessionDiv
                onClick={() => {
                  setRow(row);
                  launchRecodModal({ ...row, RecordParticipantsOnConnect: true });
                }}
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '100%',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                <VideocamIcon className="mr-2" />
                <span>{row.title}</span>
              </LaunchSessionDiv>
            </CellToolTip>
          </NarrowTableCell>
          <RecordModal />
        </TableRow>
      );
    });
  };

  const renderRecentPaymentsElements = data => {
    if (!data || data.length === 0) {
      return getNoDataRecord();
    }
    console.log('Data=====>', data);
    return data.map(row => {
      return mobileView ? (
        <div className="client-info-detail">
          <p className="d-flex justify-content-between">{getPaymentClientName(row.clientName, row.joinDate)}</p>
          <p>Service Name: {row.contributionTitle}</p>
          <p>Amount: {getPaymentText(row.amount, row.symbol, row.currency)}</p>
        </div>
      ) : (
        <TableRow>
          <NarrowTableCell>
            <CellToolTip title={getPaymentClientName(row.clientName, row.joinDate)}>
              <span>{getPaymentClientName(row.clientName, row.joinDate)}</span>
            </CellToolTip>
          </NarrowTableCell>
          <NarrowTableCell>
            <CellToolTip title={row.contributionTitle}>
              <StyledSpan
                onClick={() => {
                  redirectTo(getContributionLink(row.contributionId, row.isLeadMagnet));
                }}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {row.contributionTitle}
              </StyledSpan>
            </CellToolTip>
          </NarrowTableCell>
          <NarrowTableCell>
            <CellToolTip title={getPaymentText(row.amount, row.symbol, row.currency)}>
              <span>{getPaymentText(row.amount, row.symbol, row.currency)}</span>
            </CellToolTip>
          </NarrowTableCell>
        </TableRow>
      );
    });
  };

  const renderContractsElements = data => {
    if (!data || data.length === 0) {
      return getNoDataRecord();
    }
    return data.map(row => {
      return mobileView ? (
        <div className="client-info-detail">
          <p className="d-flex justify-content-between">
            {getContractName(row.contractName, row.signDate)}{' '}
            <DownloadIcon className="mx-2 download-icon" onClick={() => window.open(row.contractURL, '_blank')} />
          </p>
          <p className="d-flex justify-content-between align-items-center">
            {row.signURL && (
              <>
                {/* <span>
                  <span className="mr-2">Signature:</span>{' '}
                  <img src={row.signURL} alt="signature" className="signature" />
                </span> */}
                <DownloadIcon className="mx-2 download-icon" onClick={() => window.open(row.signURL, '_blank')} />
              </>
            )}
          </p>
          <p>Date; Time: {getContractDateTime(row.signDate)}</p>
        </div>
      ) : (
        <TableRow>
          <NarrowTableCell className="no-wrap">
            {getContractName(row.contractName, row.signDate)}
            <DownloadIcon
              className="mx-2 download-icon"
              onClick={() => {
                if (row.isCustom === true) {
                  const pdf = new jsPDF(options);
                  pdf.setFontSize(10);
                  pdf.html(`<html><body style="width:100%, font-size: 9px">${row.customWaiverText}</body></html>`, {
                    html2canvas: {
                      scale: 0.2645,
                    },
                    width: 675,
                    windowWidth: 675,
                    autoPaging: 'text',
                    margin: [10, 20, 30, 10],
                    callback: function (pdf) {
                      pdf.save(`${row.contractName} Waiver Form.pdf`);
                    },
                  });
                } else {
                  window.open(row.contractURL, '_blank');
                }
              }}
            />
          </NarrowTableCell>
          <NarrowTableCell className="no-wrap">
            {row.signURL && (
              <div style={{ marginLeft: '15px' }}>
                {/* <img src={row.signURL} alt="signature" className="signature" /> */}
                <DownloadIcon className="mx-2 download-icon" onClick={() => window.open(row.signURL, '_blank')} />
              </div>
            )}
          </NarrowTableCell>
          <NarrowTableCell>
            <CellToolTip title={getContractDateTime(row.signDate)}>
              <span>{getContractDateTime(row.signDate)}</span>
            </CellToolTip>
          </NarrowTableCell>
        </TableRow>
      );
    });
  };

  if (loadingPage) {
    return <Loader />;
  }

  return (
    <div className="client-details-container">
      {loading && <Loader />}
      <ClientHeader response={response} client={client} openChat={openChat} isLeadPage={isLeadPage} />
      <div className="client-info-container">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <ClientDetail
              clientId={params?.id}
              title={isLeadPage ? 'Services & Lead Magnets Enrolled' : 'Services Enrolled'}
              headers={renderHeaders(ContributionTableHeaders)}
              mobileView={mobileView}
              setData={setContributions}
              setLoading={setLoading}
              updatePage={getEnrolledContributionsOfClient}
              page={contributionTablePage}
              setPage={setContributionTablePage}
              pagesCount={getPageCount(contributions?.TotalCount)}
              pageSize={pageSize}
              paginationClass={getPaginationClass(
                getPageCount(contributions?.TotalCount),
                getPageCount(sessions?.TotalCount),
              )}
            >
              {renderContributionElements(contributions.EnrolledContributions)}
            </ClientDetail>
          </Grid>
          <Grid item xs={12} md={6}>
            <ClientDetail
              clientId={params?.id}
              title="Sessions"
              headers={renderHeaders(sessionTableHeaders)}
              mobileView={mobileView}
              setData={setSessions}
              setLoading={setLoading}
              updatePage={getAllUpCommingSessionsOfClient}
              page={sessionTablePage}
              setPage={setSessionTablePage}
              pagesCount={getPageCount(sessions.TotalCount)}
              pageSize={pageSize}
              paginationClass={getPaginationClass(
                getPageCount(sessions.TotalCount),
                getPageCount(contributions.TotalCount),
              )}
            >
              {renderUpcomingSessionsElements(sessions?.UpcomingSessionList)}
            </ClientDetail>
          </Grid>
          <Grid item xs={12} md={6}>
            <ClientDetail
              clientId={params?.id}
              title="Recent Payments"
              headers={renderHeaders(paymentTableHeaders)}
              mobileView={mobileView}
              setData={setPayments}
              setLoading={setLoading}
              updatePage={getRecentPaymentsOfClient}
              page={paymentTablePage}
              setPage={setPaymentTablePage}
              pagesCount={getPageCount(payments.TotalCount)}
              pageSize={pageSize}
              paginationClass={getPaginationClass(
                getPageCount(payments.TotalCount),
                getPageCount(contracts.TotalCount),
              )}
              isLeadPage={isLeadPage}
            >
              {isLeadPage ? getNoDataRecord() : renderRecentPaymentsElements(payments.RecentPayments)}
            </ClientDetail>
          </Grid>
          <Grid item xs={12} md={6}>
            <ClientDetail
              clientId={params?.id}
              title="Contracts"
              headers={renderHeaders(contractTableHeaders)}
              setData={setContracts}
              setLoading={setLoading}
              updatePage={getContractAndESignatures}
              mobileView={mobileView}
              page={contractsTablePage}
              setPage={setContractsTablePage}
              pagesCount={getPageCount(contracts.TotalCount)}
              pageSize={pageSize}
              paginationClass={getPaginationClass(
                getPageCount(contracts.TotalCount),
                getPageCount(payments.TotalCount),
              )}
            >
              {isLeadPage && contracts?.TotalCount === 0
                ? getNoDataRecord()
                : renderContractsElements(contracts.Contracts)}
            </ClientDetail>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

ClientPage.propTypes = {
  history: PropTypes.instanceOf(Route).isRequired,
};
export default ClientPage;
