import { UserRoles } from 'helpers/constants';
import useAccount from 'hooks/useAccount';
import usePaidTier from 'hooks/usePaidTier';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import usePaidTier from 'hooks/usePaidTier';
import useRouter from 'hooks/useRouter';

// import DefaultBillingView from './DefaultBillingView';
// import OnePlanBillingView from './OnePlanBillingView';
// import SixMonthPageView from './SixMonthBillingView';
import useShallowEqualSelector from '../../../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../../../constants';
import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType } from 'services/contributions.service';
import * as paidTier from 'selectors/paidTier';
import { ContributionType } from 'helpers/constants';
const initialState = {
  initialSelectedValues: null,
  isFeatureAllowed: false,
  selectedValues: [],
};

export const useLocalState = () => {
  const [state, updateState] = useState(initialState);
  const { currentRole } = useAccount();
  // const { isScalePlan } = usePaidTier();
  // const dispatch = useDispatch();

  // const [isLoader, setIsLoader] = useState({
  //   paidTierPlans: false,
  //   activePaidTierPlan: false,
  //   academyMemberships: false,
  //   activeAcademyMemberships: false,
  // });

  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  // const academyContributions = useSelector(paidTier.cohereAcademyContributionsSelector);
  const isScalePlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.scale;

  // const getAllPaidTiers = useCallback(() => paidTierService.getPaidTierOptions(), []);
  // const getActivePaidTier = useCallback(() => paidTierService.getCurrentPlan(), []);

  // // Correct behavior - 100%
  // const filterMembership = useCallback(
  //   res => {
  //     const filteredMemberships = [];

  //     academyContributions.forEach(item => {
  //       const matchedMembership = res.find(el => el.id === item.id);

  //       if (matchedMembership && matchedMembership.isPurchased) {
  //         filteredMemberships.push(item);
  //       }
  //       return true;
  //     });

  //     return filteredMemberships;
  //   },
  //   [academyContributions],
  // );

  // const updateActiveContributionsState = useCallback(() => {
  //   return getBoughtByType(ContributionType.contributionMembership)
  //     .then(filterMembership)
  //     .then(matchedMemberships => {
  //       dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
  //     });
  // }, [filterMembership]);

  // useEffect(() => {
  //   // this is a temp solution, we need to find a way to not repopulate it again if not needed
  //   // TODO: don't get paid tiers plans if the right version for existing user is already loaded
  //   // if (paidTierPlans.length) {
  //   //   return;
  //   // }
  //   setIsLoader(s => ({ ...s, paidTierPlans: true }));

  //   // getAllPaidTiers().then(data => {
  //   //   dispatch(paidTierActions.setPaidTierPlans(data));
  //   //   setIsLoader(s => ({ ...s, paidTierPlans: false }));
  //   // });
  // }, []);

  // useEffect(() => {
  //   setIsLoader(s => ({ ...s, activePaidTierPlan: true }));

  //   // getActivePaidTier().then(data => {
  //   //   dispatch(paidTierActions.setActivePaidTierPlan(data));
  //   //   setIsLoader(s => ({ ...s, activePaidTierPlan: false }));
  //   // });
  // }, []);

  // useEffect(() => {
  //   if (activePaidTierCurrentPlan?.default) {
  //     dispatch(setCohereAcademyStatus(true));
  //   } else {
  //     dispatch(setCohereAcademyStatus(false));
  //   }
  // }, [activePaidTierCurrentPlan, dispatch]);

  // useEffect(() => {
  //   setIsLoader(s => ({ ...s, academyMemberships: true }));

  //   // paidTierService.getAcademy().then(data => {
  //   //   dispatch(paidTierActions.setPaidTierContribution(data));
  //   //   setIsLoader(s => ({ ...s, academyMemberships: false }));
  //   // });
  // }, []);

  // useEffect(() => {
  //   setIsLoader(s => ({ ...s, activeAcademyMemberships: true }));

  //   // updateActiveContributionsState().then(() => {
  //   //   setIsLoader(s => ({ ...s, activeAcademyMemberships: false }));
  //   // });
  // }, []);

  const setState = nextState => updateState(prevState => ({ ...prevState, ...nextState }));

  useEffect(() => {
    if (currentRole === UserRoles.cohealer && isScalePlan) {
      setState({ isFeatureAllowed: true });
    }
  }, [currentRole, isScalePlan]);

  return [state, setState];
};
